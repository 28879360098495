// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1a3ddfef-e915-4caa-92ee-2f9fe38caf7f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_p1w7z4JM5",
    "aws_user_pools_web_client_id": "17b92ohddjpa1perdqmetqi6op",
    "aws_appsync_graphqlEndpoint": "https://hgno7he6gjerhgdkzgucve63kq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
